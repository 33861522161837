

<template>
  <div>

  </div>
</template>

<script>

export default {
  props: ['endpoint'],
  name: 'logout',
  methods: {
    do_logout() {
      fetch(this.endpoint,{
        headers: {
          'Content-Type': 'application/json',
          'X-TOKEN': Auth.token()
        }
      })
      .then(res => res.json())
      .then( res => {
        console.log(res);
        // remove user
        //localStorage.removeItem("user");
        Auth.logOut();

        // force update
        this.$emit('login');

        this.$router.push('/');
      })
      .catch(err => console.log(err))
    }
  },
  created: function() {
    this.do_logout();
  },
  components: {

  }
}
</script>
