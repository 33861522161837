

<template>
  <div>
    <not-found v-show="error==404"></not-found>
  </div>
</template>

<script>

import notFound from '../views/notFound'

export default {
  props: ['endpoint'],
  name: 'logout',
  data() {
    return {
      error: null
    }
  },
  computed: {
    activation_token() {
      return this.$route.params.token;
    },
  },
  methods: {
    do_activate() {
      fetch(this.endpoint + '/' + this.activation_token ,{
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then(res => {
        if(res.status == '404') {
          this.error = 404;
          return Promise.reject("404");
        }        
        else return res;
      })
      .then(res => checkResponse(res))
      .then(res => res.json())
      .then( res => {
        console.log(res);
        if(res.id && res.id>=1) {
            // save user in session storage
            localStorage.setItem("user",JSON.stringify(res));

            // force update
            this.$emit('login');

            // redirect to original path
            if(typeof(this.$route.query.to) !== 'undefined' && this.$route.query.to.length > 1) this.$router.replace({name: this.$route.query.to});
            else this.$router.replace('/');

            this.$router.go(0);  // refresh
          }
          else {
            this.error = res.error;
          }
        })

        // .catch(err => console.log(err))
    }
  },
  created: function() {
    this.do_activate();
  },
  components: {
    notFound
  }
}
</script>
