
<!-- this view is the same as editUser but intended for the user not the admin -->
<template>
    <div v-show="loaded" class="bg-light" style="min-height: 100vh">
      <drop-menu on="changePassword"></drop-menu>
      <div class="container p-1">
        <div class="row mt-5 mb-4">
          <h2>Help</h2>
        </div>
        <div class="card card-body mb-4">

          <div style="font-size: 1.2em">Help is available</div>

          <table style="margin-top: 30px" class="table table-sm table-bordered">
            <tr>
              <td><i class="fa fa-phone mx-3" style="color: #888"></i> Phone</td><td>1 (877) 415-6835</td>
            </tr>
            <tr>
              <td><i class="fa fa-envelope mx-3" style="color: #888"></i> Email / Ice Chat</td><td><a href="mailto:help@flownotices.com">help@flownotices.com</a></td>
            </tr>
            <!-- <tr>
              <td>Ice Chat</td><td>help@flownotices.com</td>
            </tr> -->
          </table>
      </div>
    </div>
</template>

<script>
  import dropMenu from '../components/drop-menu.vue'
  export default {
    props: ['title'],
    data() {
      return {
        loaded: false,
        attributes: {

        },
      }
    },
    mounted() {
      this.loaded = true;
    },
    methods: {

    },
    components: {
      dropMenu
    }
  }
</script>

<style scoped>

</style>
