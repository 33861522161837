
<template>
    <div>
      <div class="container p-1 text-left" v-if="loaded">

        <!-- <div class="row mt-5 mb-4">
          <h2>{{ $route.meta.title || "Terms of Service" }}</h2>
        </div> -->
        <div class="outerWindow">

          <div id="termsWindow" class="termsWindow" v-html="attributes.terms" @scroll="scrollFunc">


          </div>

          <div class="text-center my-5">
            <!-- <hr> -->
            <input type="checkbox" id="accept" v-model="accept" ></input><Label for="accept" class="ml-2 mr-3">I have read and agree to the Terms and Conditions</Label>
            <button class="btn btn-success" @click="submitForm" v-bind:class="{disabled:disabled}">Submit</button> <!-- v-bind:disabled="disabled"  -->
          </div>
        </div>

      </div>
      <not-found v-else-if="error==404"></not-found>
      <div class="loader" v-show="loading">
        <pulse-loader :color="'#74b3e0'" :loading="loading" :size="30" :sizeUnit="'px'"></pulse-loader>
      </div>
    </div>
</template>

<script>

import * as library from 'js-library'
import { PulseLoader } from '@saeris/vue-spinners'
import notFound from './notFound.vue'

export default {
  name: 'acceptance',
  props: {
    endpoint : {
      default: function() {
        return null;
      }
    },
    title : {
      default: function() {
        return null;
      }
    }
  },
  data: function() {
    return {
      attributes: {
        id: null,
        terms: "",
        effective_date: null
      },
      accept: false,
      completed: false,
      loaded: false,
      loading: true,
      error: null
    }
  },
  computed: {
    disabled() {
      if(this.completed && this.accept) return false;
      return true;
    }
  },
  methods: {
    submitForm() {
      if(!this.completed) {
        alert("Please read the entire Terms and Conditions before Agreeing");
      }
      else if(!this.accept) {
        alert("You must accept the Terms and Conditions in order to use the Flow Notices Site");
      }
      else {
        this.save();
      }
    },
    save() {
      fetch(this.endpoint,{
        method: 'post',
        body: JSON.stringify({
          tos_id: this.attributes.id
        }),
        headers: {
          'content-type': 'application/json',
          'X-TOKEN': Auth.token()
        }
      })
      .then(res => this.checkResponse(res))
      .then(res => res.json())
      .then(data => {
        console.log(data);
        if(data.id) {
          // this works
          // var p = this.$router.options.routes.filter(route => {
          //     if(route.name === this.$route.query.to) return true;
          //     return false;
          // })[0];
          // console.log(p);
          // this.$router.replace(p);



          // this also works
          if(typeof(this.$route.query.to) !== 'undefined' && this.$route.query.to.length > 1) this.$router.replace({name: this.$route.query.to});
          else this.$router.push('/');

          // but if you don't refresh, none if it works
          this.$router.go(0);
        }
      })
      .catch(err => console.log(err))
    },
    fetchItem() {
      return new Promise(function(resolve,reject) {
        fetch(this.endpoint, {
            headers: {
              'Content-Type': 'application/json',
              'X-TOKEN': Auth.token()
            }
          })  // always fetches most recent
          .then(res => res.json())
          .then(res => {
            for(var item in res) {
              this.attributes[item] = res[item];
            }
            this.loading = false;
            this.loaded = true;
            resolve(res);
          })
          .catch(err => {
            console.log(err);
            reject(err);
          });
        }.bind(this));
    },
    scrollFunc() {
        var termsWindow = document.getElementById("termsWindow");
        var scrollPos = termsWindow.scrollTop / (termsWindow.scrollHeight - termsWindow.clientHeight);
        console.log("scrollPos", scrollPos, termsWindow.scrollTop, termsWindow.scrollHeight, termsWindow.clientHeight);
        if(scrollPos > 0.95) {
          this.completed = true;
        };
    }
  },
  created: function() {
    console.log("wa da tay");
    this.fetchItem();



  },
  watch: {
    '$route': function(to, from) {
      console.log("endpoint change",to,from);
      this.loading = true;
      this.fetchItem();
    }
  },
  components: {
    PulseLoader,
    notFound
  }
}
</script>

<style scoped>

.outerWindow {
  /* border: 1px solid #777; */
  padding: 10px;
}

.termsWindow {
  margin-top: 20px;
  border: 1px solid #ccc;
  /* border: 1px solid black; */
  max-height: 60vh;
  padding: 12px;
  overflow-y: scroll;
  background: white;
  box-sizing: border-box;
}
.loader {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
</style>
