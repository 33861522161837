
// import vue
import Vue from 'vue'
import VueRouter from 'vue-router'
//import Vuex from 'vuex'

// this is for home-grown error capture
// Vue.config.errorHandler = function (err, vm, info) {
//   // handle error
//   // `info` is a Vue-specific error info, e.g. which lifecycle hook
//   // the error was found in. Only available in 2.2.0+
//   console.log(err,vm,info);
// }

// assets
import logo from './../images/logo4.png'
import bootstrap from 'bootstrap/dist/css/bootstrap';
import fontawesome from '@fortawesome/fontawesome-free/css/all.css';
import classes from './../css/main.css';

// app
import App from './components/App-router.vue'

// utility views
import login from './components/login'
import logout from './components/logout'
import changePassword from './views/changePassword.vue'
import updateUser from './views/updateUser.vue'
import privacyStatement from './views/privacyStatement.vue'
import acceptance from './views/acceptance.vue'
import tos from './views/tos.vue'
import help from './views/help.vue'
import activate from './components/activate.vue'
import reset from './components/reset.vue'

// domain views
// import capacityReleaseDetail from './views/capacityReleaseDetail.vue'
// import contractDetail from './views/contractDetail.vue'
import noticeDetail from './views/noticeDetail.vue'
import subscriptions from './views/subscriptions.vue'
import notices from './views/notices.vue'
import notFound from './views/notFound.vue'

// set server location
// var server = 'https://pipelinedata.fractionsdata.com/api';
// var server = 'http://localhost:9995';
var server = 'https://api.flownotices.com';


import * as Sentry from '@sentry/browser';
// Sentry.init({ dsn: 'https://88dde8aa08b446aeb835db3f45ed4dff@o400737.ingest.sentry.io/5259413' });

import { Vue as VueIntegration } from '@sentry/integrations';
//
// Sentry.init({
//   dsn: 'https://88dde8aa08b446aeb835db3f45ed4dff@o400737.ingest.sentry.io/5259413',
//   integrations: [new VueIntegration({Vue, attachProps: true})],
// });

// set up vue
Vue.use(VueRouter)

// remove console.log for npm run build
if (process.env.NODE_ENV === 'production') { // Or, `process.env.NODE_ENV !== 'production'`
  console.log = function(){};
  window.console = console;
}


const router = new VueRouter({
  mode: 'history',
  //base: __dirname,
  base: '/',
  routes: [

    // common
    {path: '', name: 'home', component: notices, props: { title: 'FlowNotices', endpoints: {'notices': server + '/notices', 'favorites': server + '/favorites'}}, meta: { requireLogin: true }},
    {path: '/', name: '/', component: notices, props: { title: 'FlowNotices', endpoints: {'notices': server + '/notices', 'favorites': server + '/favorites'}}, meta: { requireLogin: true }},
    {path: '/login', name: 'login', component: login, props: { title: 'FlowNotices', endpoint: server + '/login', logo: logo, size:'600px', margin:'15px' }},
    {path: '/logout', name: 'logout', component: logout, props: { endpoint: server + '/logout' }},

    // // user functions
    {path: '/changePassword', component: changePassword, props: { title: 'Update Password', endpoint: server + '/updatePassword'}, meta: { requireLogin: true }},
    {path: '/updateUser', component: updateUser, props: { title: 'Update My Information', endpoints: {'users': server + '/users', 'user': server + '/user'}}, meta: { requireLogin: true }},
    {path: '/privacyStatement', name: 'privacyStatement', component: privacyStatement, props: { title: 'Privacy Notice', endpoint: server + '/privacy'}, meta: { requireLogin: true }},
    {path: '/tos', name: 'tos', component: tos, props: { title: 'Terms of Service', endpoint: server + '/tos'}, meta: { requireLogin: true }},
    {path: '/acceptance', name: 'acceptance', component: acceptance, props: { title: 'Terms of Service', endpoint: server + '/tos'}, meta: { requireLogin: true }},
    {path: '/help', name: 'help', component: help, props: { title: 'Help', endpoint: server + '/help'}, meta: { requireLogin: true }},
    //
    // // domain public
    {path: '/subscriptions', name: 'subscriptions', component: subscriptions, props: { title: 'Manage Subscriptions', endpoint: server + '/subscriptions'}, meta: { requireLogin: true }},
    // {path: '/notices', name: 'notices', component: notices, props: { title: 'Notices', endpoint: server }, meta: { requireLogin: true }},
    {path: '/notices/:id', name: 'noticeDetail', component: noticeDetail, props: { title: 'View Notice', endpoint: server + '/notices', notice: {}},  meta: { requireLogin: true }},

    {path: '/activate/:token', name: 'activate', component: activate, props: { title: 'Activate Account', endpoint: server + '/activate'}, meta: { requireLogin: false }},
    {path: '/reset/:token', name: 'reset', component: reset, props: { title: 'Reset Account', endpoint: server + '/reset'}, meta: { requireLogin: false }},

    { path: '*', component: notFound }
  ]
})

window.Auth = {

  isLoggedIn() {
    var user = JSON.parse(localStorage.getItem("user"));
    //console.log(user);
    if(typeof(user) == 'undefined' || user == null) return false; // not even logged in
    return true;
  },
  logOut() {
    localStorage.removeItem("user");
  },
  logIn(user) {
    localStorage.setItem("user",JSON.stringify(user));
  },
  user() {
    var user = JSON.parse(localStorage.getItem("user"));
    //console.log(user);
    if(typeof(user) == 'undefined' || user == null) return false; // not even logged in
    return user;
  },
  is(role) {
    var user = JSON.parse(localStorage.getItem("user"));

    if(typeof(user) == 'undefined' || user == null) return false; // not even logged in

    if(typeof(user.roles) == 'undefined' || user.roles.length == 0) return false;  // user has no roles

    if(typeof(role) == 'string') return user.roles.filter(function(item){
      return item.name == role;
    }).length;  // only matching one role

    if(typeof(role) == 'array') return user.roles.filter(function(item){
      return role.indexOf(item.name) != -1;
    }).length;  // only matching one role

    return false;
  },
  token() {
    if(this.user()) return this.user().api_token;
    else return null;
  },
  userId() {
    if(this.user()) return this.user().id;
    else return null;
  }
}

// this lets you use Auth inside templates
Vue.prototype.Auth = window.Auth;

// this lets you use window inside templates
//Vue.prototype.window = window;

router.beforeEach((to, from, next) => {
  //console.log([to,from,next]);
  if(to.meta.requireLogin && !Auth.isLoggedIn()) {
    console.log("login required, redirecting");
    router.replace({path: '/login', query: { to: to.path }});
  }
  if(to.meta.requireRole && !Auth.is(to.meta.requireRole)) {
    console.log("login required, redirecting");
    router.replace({path: '/login', query: { to: to.path }});
  }

  next();
})

router.afterEach((to, from) => {
    document.title = to.meta.title || "FlowNotices";
});

const app = new Vue({
    //store,
    router,
    render: createEle => createEle(App)
}).$mount('#app');

window.checkResponse = function(response) {
    if(response.status == 403) {
      console.log("login required, redirecting");
      window.Auth.logOut();
      // console.log(JSON.parse(localStorage.getItem("user")));
      router.replace({path: '/login', query: { to: router.history.current.path.substring(1) }});
    }
    else if(response.status == 451) {
      console.log("new TOS, redirecting");
      router.push({path: '/acceptance', query: { to: router.history.current.path.substring(1) }});
    }
    else if(response.status == 500) {
      console.log("500 server error");
      Sentry.captureException(new Error("500 Server Error"));
      router.replace({path: '/error'});
      return Promise.reject();
    }
    else if(response.status == 404) {
      console.log("404 not found");
      Sentry.captureException(new Error("404 Not Found"));
      // router.replace({path: '/notFound'});
      this.error = 404;
      return Promise.reject("404");
    }
    else if(response.status == 307) {
      response.text().then(txt => {
        console.log("server is redirecting to: " , txt);

        router.push({path: txt, query: { to: router.history.current.path.substring(1) }});
      });
    }
    else {
      return response;
    }
}

// this lets you use Auth inside templates
Vue.prototype.checkResponse = window.checkResponse;

Vue.prototype.appVersion = '1.1.0';



// myUndefinedFunction();
//
// try {
//   undefinedObject.myUndefinedFunction();
// }
// catch(e) {
//
//   console.log({
//     "error": e.stack,
//     "user": window.Auth.user(),
//     "navigator": navigator
//
//   });
//   // console.log({
//   //   "filename": e.fileName,
//   //   "lineNumber": e.lineNumber,
//   //   "columnNumber": e.columnNumber,
//   //   "stack": e.stack,
//   //   "description": e.description,
//   //   "number": e.number,
//   // });
//   // console.log(e);
// }

//var str = "feet";
// var bla = str[6];
