
<template>
  <nav class="no-print" @focusout="show=show">
    <span @click="show=!show" class="icon">
      &#9776;
      <!-- <i class="fa fa-cog fa-lg"></i> -->
      <!-- <i class="fa fa-angle-down"></i> -->
    </span>
    <ul v-show="show">
      <!-- <li v-if="Auth.is('Admin')"><router-link to="/pipelines">Pipelines</router-link></li>
      <li v-if="Auth.is('Admin')"><router-link to="/locations">Locations</router-link></li>
      <li v-if="Auth.is('Admin')"><router-link to="/zones">Zones</router-link></li>
      <li v-if="Auth.is('Admin')"><router-link to="/shippers">Shippers</router-link></li>
      <li v-if="Auth.is('Admin')"><router-link to="/endUsers">EndUsers</router-link></li>
      <li v-if="Auth.is('Admin')"><router-link to="/capacityRelease">Capacity Release</router-link></li>
      <li v-if="Auth.is('Admin')"><router-link to="/contracts">Contracts</router-link></li>
      <li v-if="Auth.is('Admin')"><router-link to="/rateschedules">Rate Schedules</router-link></li> -->

      <!-- <li v-if="on!=='subscribed'"><a href="/subscribed">View Subscribed Notices</a></li> -->
      <li v-if="on!=='notices'"><a href="/">View Notices</a></li>
      <li v-if="on!=='updateUser'"><a href="/updateUser">Update User Info</a></li>
      <li v-if="on!=='changePassword'"><a href="/changePassword">Change Password</a></li>
      <li v-if="on!=='subscriptions'"><a href="/subscriptions">Manage My Subscriptions</a></li>
      <li v-if="on!=='privacystatement'"><a href="/privacystatement">View Privacy Statement</a></li>
      <li v-if="on!=='tos'"><a href="/tos">View Terms of Service</a></li>
      <li v-if="on!=='help'"><a href="/help">Help</a></li>
      <li><a href="/logout">Logout</a></li>
    </ul>
  </nav>
</template>

<script>
  export default {
    props: ['on'],
    data() {
      return {
        show: false
      }
    }
  }
</script>

<style scoped>
@media print
{
    .no-print, .no-print *
    {
        display: none !important;
    }
}
/* @media screen and (min-width: 600px) { */
	nav, nav ul, nav ul li, nav ul li a, nav ul ul, nav ul ul li, nav ul ul li a, nav ul ul ul {
	  box-sizing: border-box;
	  margin: 0;
	  z-index: 125;
	  cursor: pointer;
    /* text-align: left; */
    /* font-family: "Tahoma"; */
    /* color: #444; */
    /* color: red; */
	}

  .icon {
    font-size: 1.5em;
    padding: 1em; /* makes a larger clickable area */
    margin: -1em; /* removes spacing effects of the above */
  }

	nav {
    text-align: right;
    /* padding-right: 50px; */
    color: #111;  /* color of the icon and the word "settings" */
    /* position: relative; */
    position: absolute;
    top: 5px;
    right: 20px;
    font-size: 1.1em;
  }

  @media screen and (min-width: 600px) {
    nav {
      position: fixed;
    }
  }

	nav ul {
    /* display: none; */  /* to make hover work */
    position: absolute;
    top: 2.1em;
    right: 0px;
    list-style: none;
    border: 1px solid #dadada; /* border of the window */
    background-color: #f8f9fa; /* color of the window */
    background-color: #fff; /* color of the window */
    /* background-color: rgba(255,255,255,1); */
    color: #ddd;  /* color of the menu font */
    border-radius: 4px;
    padding: 10px;
    z-index: 5;
    width: auto;
    white-space: nowrap;
    text-align: center;
    /* font-family: "Lucida Console"; */
	}

  /* nav:hover ul {
    display: inline-block;
  } */

	nav ul li {
    /* padding: 3px; */
    border-bottom: 1px solid #dadada;
    cursor: pointer;
	}

  nav ul li:last-child {
    border-bottom: none;
  }

	nav ul li:hover > ul {
	  display: inline-block;
	}

  .text {
    margin-left: 15px;
    font-family: "Lucida Console";
  }

	nav ul li a {
	  display: block;
	  color: #212529;
	  text-decoration: none;
    padding: 10px 40px;
    font-size: 1.1em;
    letter-spacing: 1.2px;
	}
  nav ul li a:hover {
    /* position: relative;
    right: 10px; */
	  color: #868d93;
	  text-decoration: none;
    /* font-weight: bolder; */
	}


	/* nav ul ul {
	  display: none;
	  background: #fff;
	  position: absolute;
	  top: 100%;
	  box-shadow: -3px 3px 10px -2px rgba(0, 0, 0, 0.1);
	  border: 1px solid #dae0e5;
	  white-space: nowrap;
	  margin: 0;
	}

	nav ul ul li {
	  float: none;
	  position: relative;
	  margin: 0;
	}

	nav ul ul li a {
	  white-space: nowrap;
	  margin: 0;
	}

	nav ul ul ul {
	  position: absolute;
	  left: 100%;
	  top: 0;
	}

  .unfinished {
    background-color: #cde;
  } */


</style>
