
<!-- this view is the same as editUser but intended for the user not the admin -->
<template>
    <div class="bg-light">
      <div v-if="loaded">
        <drop-menu on="subscriptions"></drop-menu>
        <div class="container p-1">
          <div class="row mt-5 mb-4">
            <h2>Manage My Subscriptions</h2>
            <!-- <span class="ml-auto" style="float:right">
              <button class="btn btn-success" @click="subscribeAll">Subscribe All</button>
              <button class="btn btn-secondary" @click="unSubscribeAll">Un-Subscribe All</button>
            </span> -->
          </div>

          <div class="text-danger mb-4 mx-auto" v-for="err in errors" v-text="err.error"></div>

          <!-- <div class="form-row text-right mb-4"> -->

          <!-- </div> -->

          <div v-for="subscription in items" class="card card-body bg-white mb-4">

            <div class="form-row">

                <div class="col-4">

                  <h5 v-text="subscription.pipeline.name"></h5>
                  <div v-if="subscription.critical == 1">Critical</div>
                  <div v-else>Non-Critical</div>
                </div>

                <div class="col-1 mx-5 text-center">
                  <label>Subscribed </label>
                  <input v-model="subscription.subscribed" type="checkbox" style="width: 1.7rem; height: 1.7rem; margin:5px" @change="sendChange(subscription)">
                </div>

                <div class="col">
                  <label>Containing Only </label>
                  <input type="text" class="form-control" v-model="subscription.only" placeholder="Eg: Alert, OFO, Maintenance" @blur="sendChange(subscription)">
                </div>

                <div class="col">
                  <label>Not Containing</label>
                  <input type="text" class="form-control" v-model="subscription.not" placeholder="Eg: Cashout, Allocation" @blur="sendChange(subscription)">
                </div>

            </div>

          </div>

          <!-- <div class="text-center">
            <button @click="save" class="btn btn-success ml-auto mr-4"><i class="fa fa-check"></i> Save</button>
            <router-link to="/" class="btn btn-secondary mr-auto"><i class="fa fa-times"></i> Cancel</router-link>
          </div> -->
        </div>
      </div>
      <not-found v-else-if="error==404"></not-found>
      <div class="loader" v-else>
        <pulse-loader :color="'#74b3e0'" :loading="loading" :size="30" :sizeUnit="'px'"></pulse-loader>
      </div>
    </div>
</template>

<script>
  import {crudMixin} from 'vue-libs'
  import dropMenu from '../components/drop-menu.vue'
  import { PulseLoader } from '@saeris/vue-spinners'
  import notFound from './notFound.vue'

  export default {
    name: 'subscriptions',
    props: ['title','endpoint'],
    // mixins: [crudMixin],
    data() {
      return {
        items: [],
        errors: [],
        loaded: false,
        changed: false,
        error: null
      }
    },
    created() {
      console.log(this.endpoint);
      // this.fetchVM();
      this.fetchItems();

    },
    beforeRouteLeave (to, from, next) {
      // called when the route that renders this component is about to
      // be navigated away from.
      // has access to `this` component instance.
      if(this.changed) {
        if(confirm("You have unsaved changes.  Are you sure you want to leave?")) next();
      }
    },
    methods: {
      fetchItems() {
        //this.loading=true;
        fetch(this.endpoint, {
            headers: {
              'Content-Type': 'application/json',
              'X-TOKEN': Auth.token()
            }
          })
          .then(res => {
            this.waiting = false;
            return res.json();
          })
          .then(res => {

            this.items = res;

            // the below is because php returns boolean as string
            this.items.map(function(sub){
              sub.subscribed = (sub.subscribed == "1") ? true: false;
            });

            this.loaded = true;

          })
          .catch(err => console.log(err));
      },
      save() {
        this.errors = {};
        this.messages = [];
        fetch(this.endpoint,{
          method: 'post',
          body: JSON.stringify(this.items),
          headers: {
            'content-type': 'application/json',
            'X-TOKEN': Auth.token()
          }
        })
        .then(res => res.json())
        .then( data => {
          if(data.errors && data.errors.length !== 0) {
            this.error(data.errors);
          }
          else {
            this.changed = false;
            this.$router.push('/');
            this.$router.go(0);
          }
        })
        .catch(err => console.log(err))
      },
      sendChange(subscription) {
        this.errors = {};
        this.messages = [];
        fetch(this.endpoint,{
          method: 'post',
          body: JSON.stringify([subscription]),
          headers: {
            'content-type': 'application/json',
            'X-TOKEN': Auth.token()
          }
        })
        .then(res => res.json())
        .then( data => {
          if(data.errors && data.errors.length !== 0) {
            this.error(data.errors);
          }
        })
        .catch(err => console.log(err))
      },
      subscribeAll() {
        for(var item in this.items) {
          this.items[item].subscribed = 1;
        }
      },
      unSubscribeAll() {
        for(var item in this.items) {
          this.items[item].subscribed = 0;
        }
      }
    },
    components: {
      dropMenu,
      PulseLoader,
      notFound
    }
  }
</script>
<style scoped>
.loader {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
</style>
