
<template>
    <div class="bg-light">
      <div v-if="loaded">
        <drop-menu on="tos"></drop-menu>
        <div class="container p-3 text-left">

          <div v-html="attributes.terms" class="mt-5"></div>

        </div>
      </div>
      <not-found v-else-if="error==404"></not-found>
      <div class="loader" v-else>
        <pulse-loader :color="'#74b3e0'" :loading="loading" :size="30" :sizeUnit="'px'"></pulse-loader>
      </div>
    </div>
</template>

<script>

import * as library from 'js-library'
import dropMenu from '../components/drop-menu.vue'
import { PulseLoader } from '@saeris/vue-spinners'
import notFound from './notFound.vue'

export default {
  name: 'tos',
  props: {
    endpoint : {
      default: function() {
        return null;
      }
    },
    title : {
      default: function() {
        return null;
      }
    }
  },
  data: function() {
    return {
      loaded: false,
      attributes: {
        id: null,
        terms: "",
        effective_date: null
      },
      error: null
    }
  },
  computed: {

  },
  methods: {
    fetchItem() {
      return new Promise(function(resolve,reject) {
        fetch(this.endpoint, {
            headers: {
              'Content-Type': 'application/json',
              'X-TOKEN': Auth.token()
            }
          })  // always fetches most recent
          .then(res => res.json())
          .then(res => {
            for(var item in res) {
              this.attributes[item] = res[item];
            }
            this.loading = false;
            this.loaded = true;
            resolve(res);
          })
          .catch(err => {
            console.log(err);
            reject(err);
          });
        }.bind(this));
    },
  },
  created: function() {
    console.log("wa da tay");
    this.fetchItem();
  },
  watch: {
    '$route': function(to, from) {
      console.log("endpoint change",to,from);
      this.loading = true;
      this.fetchItem();
    }
  },
  components: {
    dropMenu,
    PulseLoader,
    notFound
  }
}
</script>

<style scoped>
.card table th, .card table td {
  border-top: none;
}
.row {
  width: 100%;
  text-align: center;
}
.loader {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
</style>
