
<!-- this view is the same as editUser but intended for the user not the admin -->
<template>
    <div class="bg-light" style="min-height: 100vh">
      <div v-if="loaded">
        <drop-menu on="changePassword"></drop-menu>
        <div class="container p-1">
          <div class="row mt-5 mb-4">
            <h2>Password Update</h2>
          </div>
          <div class="card card-body mb-4">

            <!-- <div class="form-group">
              <label class="col-sm-4 col-form-label">Current Password</label>
              <input type="password" class="form-control" v-model="attributes.current_password">
            </div> -->

            <div class="form-group">
              <label class="col-sm-12 col-form-label">New Password</label>
              <input type="password" class="form-control" v-model="attributes.new_password" @keyup.enter="$refs.submitbutton.click()">
            </div>

            <div class="form-group">
              <label class="col-sm-12 col-form-label">Confirm New Password</label>
              <input type="password" class="form-control" v-model="attributes.confirm_new_password" @keyup.enter="$refs.submitbutton.click()">
            </div>

            <div class="text-danger mb-4 mx-auto" v-for="err in errors" v-text="err.error"></div>

          </div>

          <div class="text-center">
            <button @click="save" class="btn btn-success ml-auto mr-4" ref="submitbutton"><i class="fa fa-check"></i> Save</button>
          </div>
        </div>
      </div>
      <not-found v-else-if="error==404"></not-found>
      <div class="loader" v-else>
        <pulse-loader :color="'#74b3e0'" :loading="loading" :size="30" :sizeUnit="'px'"></pulse-loader>
      </div>
    </div>
</template>

<script>
  import dropMenu from '../components/drop-menu.vue'
  import { PulseLoader } from '@saeris/vue-spinners'
  export default {
    props: ['title', 'endpoint'],
    data() {
      return {
        loaded: false,
        loading: true,
        attributes: {
          current_password: '',
          new_password: '',
          confirm_new_password: ''
        },
        errors: [],
        error: null
      }
    },
    mounted() {
      this.loaded = true;
    },
    methods: {
      save() {
        fetch(this.endpoint, {
          method: 'post',
          body: JSON.stringify(this.attributes),
          headers: {
            'content-type': 'application/json',
            'X-TOKEN': Auth.token()
          }
        })
        .then(res => this.checkResponse(res))
        .then(res => res.json())
        .then( res => {
          console.log(res);
          if(res.id && res.id>=1) {
            localStorage.setItem("user",JSON.stringify(res));  // to store new api key
            alert("Password Updated");
            if(typeof(this.$route.query.to) !== 'undefined' && this.$route.query.to.length > 1) this.$router.replace({name: this.$route.query.to});
            else this.$router.push('/');
            this.$router.go(0);
          }
          else {
              this.errors = res;
          }

        })
        .catch(err => console.log(err))
      }
    },
    components: {
      dropMenu,
      PulseLoader
    }
  }
</script>
<style scoped>
.loader {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
</style>
