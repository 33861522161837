
<template>
    <div class="bg-light" style="min-height: 110vh"> <!-- min-height is so the background color holds on refresh notices.  it is 110 so that the scroll bar is always visible -->

      <div class="container p-1 pt-5" v-if="loaded">

        <!-- messaging -->
        <div class="text-danger mb-4 mx-auto" v-for="err in errors" v-text="err.error"></div>

        <!-- menu -->
        <drop-menu on="notices"></drop-menu>

        <!-- logo -->
        <div class="text-center mb-5 mt-3">
          <img :src="logo" height="44px" class="mx-auto"></img>
        </div>

        <!-- search box -->
        <div class="card card-body mb-5 p-3">
          <div class="row">
            <div class="col-3"><strong>Search</strong></div>
            <div class="custom-control custom-switch col-9 text-right">
              <label style="margin-right: 40px" for="customSwitches">All Notices</label>
              <input type="checkbox" class="custom-control-input" id="customSwitches" v-model="subscribed" @change="fetchNoticesFresh">
              <label class="custom-control-label" for="customSwitches">Subscribed Only</label>
            </div>
            <div class="subscribe-tooltip" v-if="show_subscribe_tooltip" @click="show_subscribe_tooltip=false">You do not have any subscriptions set up. <br><a class="btn btn-success mt-2" href="/subscriptions">Go to my subscriptions</a></div>
          </div>
          <hr>
          <div class="form-row" v-if="subscribed">

              <div class="col-12">
                <label>Keywords</label>
                <input type="text" class="form-control" v-model="search.keywords"  @input="fetchNoticesFresh">
              </div>

          </div>
          <div class="form-row" v-else>

              <div class="col-4">
                <label>Pipeline</label>
                <vue-multiselect class="form-control" v-model="search.pipeline_id" :options="lists.pipeline_id" @input="fetchNoticesFresh"></vue-multiselect>
              </div>

              <div class="col-2">
                <label>Critical</label>
                <select class="form-control" v-model="search.critical" @change="fetchNoticesFresh">
                  <option value="">All</option>
                  <option value="1">Critical</option>
                  <option value="0">Non-Critical</option>
                </select>
              </div>

              <div class="col-6">
                <label>Keywords</label>
                <input type="text" class="form-control" v-model="search.keywords"  @input="fetchNoticesFresh">
              </div>

          </div>
        </div>

        <!-- notices -->
        <!-- <div v-for="notice in notices" class="card card-body mb-3 notice" @click="detail(notice.id)"> -->
        <div v-for="notice in notices" class="card card-body mb-3 notice" @click="detail(notice)">
          <div class="row">
            <div v-text="notice.subject" class="col-9 noticeSubject"></div>
            <div class="col-3 text-right">
              <span v-if="+notice.critical" class="noticeCritical font-weight-bolder">Critical</span>
              <span v-else class="noticeCritical">Non-Critical</span>
            </div>
          </div>
          <hr>
          <div class="row text-right">
            <div v-text="notice.pipeline.name" class="col-8 noticePipeline mb-2 text-left"></div>

            <div v-text="formatDate(notice.posted_datetime)" class="col-4 noticePosted"></div>

          </div>
        </div>


      </div>
      <not-found v-else-if="error==404"></not-found>
      <div class="loader" v-else>
        <pulse-loader :color="'#74b3e0'" :loading="loading" :size="30" :sizeUnit="'px'"></pulse-loader>
      </div>
    </div>
</template>

<script>
  // import {reportMixin} from 'vue-libs'
  import moment from 'moment'
  import {vueMultiselect} from 'vue-components'
  import dropMenu from '../components/drop-menu.vue'
  import logo from '../../images/logo4.png'
  import * as library from 'js-library'
  import { PulseLoader } from '@saeris/vue-spinners'
  import notFound from './notFound.vue'

  export default {
    props: ['endpoints'],
    name: 'notices',
    // mixins: [reportMixin],
    data() {
      return {
        notices: [],
        errors: [],
        logo: logo,
        search: {
          pipeline_id: [],  /* if you remove this, the multiselect breaks */
          critical: "",
          keywords: '',
          page: 1
        },
        subscribed: true,
        fields: {},
        loading:true,
        loaded:false,
        show_subscribe_tooltip: false,
        error: null
      }
    },
    mounted() {
      console.log(this.endpoints);
      this.fetchVM();
      this.fetchNotices();
      window.addEventListener('scroll', this.handleScroll);
    },
    methods: {
      fetchNoticesFresh() {

        // the below does a debounce on the search: https://stackoverflow.com/questions/42199956/how-to-implement-debounce-in-vue2
        if (this.timeout) clearTimeout(this.timeout);
         this.timeout = setTimeout(() => {
           this.search.page = 1;
           // this.notices = [];  // race condition
           this.fetchNotices(true);
         }, 600);
      },
      fetchNotices(replace = false) {

        if(this.subscribed) {
          // var url = 'https://pipelinedata.fractionsdata.com/api/favorites';
          var url = this.endpoints.favorites;

          var s = {};
          if(this.search.keywords.length) s.search = this.search.keywords;
          if(this.search.page > 1) s.page = this.search.page;
        }
        else {
          // var url = 'https://pipelinedata.fractionsdata.com/api/notices';
          var url = this.endpoints.notices;

          var s = {};
          if(this.search.pipeline_id.length) s.pipeline_id = this.search.pipeline_id;
          if(this.search.critical !== "") s.critical = this.search.critical;
          if(this.search.keywords.length) s.search = this.search.keywords;
          if(this.search.page > 1) s.page = this.search.page;
        }

        console.log("fetching notices from: " + url + '?' + library.$objToHttp(s) + 'using token: ' + Auth.token());
        // console.log(Auth.user());

        fetch(url + '?' + library.$objToHttp(s), {
          headers: {
            'Content-Type': 'application/json',
            'X-TOKEN': Auth.token()
          }
        })
        .then(res => checkResponse(res))
        .then(res => res.json())
        .then(res => {
          console.log(res)
          if(res.data == 'no subscriptions') {
            this.subscribed = false;
            this.show_subscribe_tooltip = true;
            // this.fetchNotices();  // don't need to do this because changing the switch re-fetches
          }
          else if(res.data.length) {
            if(replace) this.notices = [];
            for(var i=0; i< res.data.length; i++) {
              this.notices.push(res.data[i])
            }
          }
          // else if(!this.notices.length && this.subscribed) {  // no subscribed messages
          //
          // }

          this.loading = false;
          this.loaded = true;
        })
        .catch(err => {
          console.log(err);
        });

      },
      fetchVM() {
        fetch(this.endpoints.notices, {
          method: 'OPTIONS',
          headers: {
            'Content-Type': 'application/json',
            'X-TOKEN': Auth.token()
          }
        })
          .then(res => res.json())
          .then(res => {
              this.fields = res.fields;
          })
          .catch(err => console.log(err));
      },

      formatDate(d) {
        return moment(d).format('MMMM Do YYYY, h:mm:ss a')
      },
      // detail(id) {
      detail(notice) {
        // this.$router.push({path: '/notices/'+id});
        this.$router.push({ name: 'noticeDetail', params: {notice: notice, id: notice.id, endpoint: this.endpoints.notices}});
      },
      handleScroll: function() { /* function determines when it is time to fetch more, uses document window */

        // var st = document.body.scrollTop;
        var st = Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop)
        var wh = window.innerHeight;
        var sh = document.body.scrollHeight
        // var dh = document.body.offsetHeight;
        // var max = sh - wh;
        // var perc = st/max;
        var rm = sh - wh - st;
        // console.log(st, wh, sh, dh, max, perc, rm);
        // console.log('scroll: '+perc.toString().substring(2,4) + '%');

        // if((perc>.9) && (this.loading == false)) {
        if((rm<120 && st > 0) && (this.loading == false)) {  // if you don't check for st, it will fetch more results as you change pages
          console.log("fetching more results");
          this.loading = true;
          this.nextPage();
        }
      },
      nextPage() {
        this.search.page += 1;
        this.fetchNotices();
      }
    },
    // mounted () {
    //
    // },
    beforeDestroy () {
      window.removeEventListener('scroll', this.handleScroll);
    },
    watch: {
      // '$route': function(to, from) {
      //   console.log("endpoint change",to,from);
      //   // if(to.name === 'notices') {
      //   //   window.addEventListener('scroll', this.handleScroll);
      //   //
      //   // }
      //   // else {
      //   //   window.removeEventListener('scroll', this.handleScroll);
      //   // }
      //   if(!this.notices.length) this.fetchNotices();
      // }
    },
    computed: {
      lists() {
        var output = {};
        for(var field in this.fields) {
          // console.log(field, this.fields[field].format);
          if(this.fields[field].hasOwnProperty("list")) output[field] = this.fields[field].list;
        }
        return output;
      }
    },
    components: {
      vueMultiselect,
      dropMenu,
      PulseLoader
    }
  }
</script>

<style scoped>

.noticeSubject {
  font-size: 1.3rem;
  /* font-family: Verdana; */
}
.noticePipeline {
  font-style: italic;
  font-size: 1.1rem;
}
.noticePosted {
  float: right;
  font-style: italic;
  font-size: .9rem;
}
.noticeCritical {
  /* float: left; */
  font-size: .9rem;
}
.noticeCard {
  cursor: pointer;

}
.notice.card-body {

}
.notice.card-body:hover {
  background-color: #f2f9ff;
  cursor: pointer;
}
hr {
  margin-top: 2px;
  margin-bottom: 8px;
}
.custom-control-input:checked ~ .custom-control-label::before { /* this is the toggle switch */
  background-color: #5dc1b7;
  border-color: #5dc1b7;
}
.loader {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.subscribe-tooltip {
  position: absolute;
  top: 0%;
  left: 105%;
  background: rgb(50,50,80,.05);
  /* background: rgb(25,100,200,.05); */
  /* color: #eee; */
  /* border: 1px solid rgb(25,100,200,.25); */
  padding: 15px;
  border: 1px solid #ccd;
  border-radius: 8px;
  text-align: center;
  white-space: nowrap;
}

</style>
