
<!-- this view is the same as editUser but intended for the user not the admin -->
<template>
    <div class="bg-light" style="min-height: 101vh">
      <div v-if="loaded">
        <drop-menu on="updateUser"></drop-menu>
        <div class="container p-1 pb-5">
          <div class="row mt-5 mb-4">
            <h2>Update User Information</h2>
            <!-- <div class="ml-auto"><router-link to="/users" class="ml-auto btn btn-primary"><i class="fa fa-arrow-left"></i> Users</router-link></div> -->
          </div>
          <div class="card card-body mb-4">

            <div class="form-group">
              <label class="col-sm-12 col-form-label">First Name</label>
              <input type="text" class="form-control" v-model="attributes.first_name">
            </div>

            <div class="form-group">
              <label class="col-sm-12 col-form-label">Last Name</label>
              <input type="text" class="form-control" v-model="attributes.last_name">
            </div>


            <div class="form-group">
              <label class="col-sm-12 col-form-label">E-mail</label>
              <input type="text" class="form-control" v-model="attributes.email">
            </div>

            <!-- <div class="form-group">
              <label class="col-sm-12 col-form-label">User Name</label>
              <input type="text" class="form-control" v-model="attributes.username">
            </div> -->

            <div class="form-group">
              <label class="col-sm-12 col-form-label">Phone</label>
              <input type="text" class="form-control" v-model="attributes.phone">
            </div>

            <div class="form-group">
              <label class="col-sm-12 col-form-label">Cell Phone</label>
              <input type="text" class="form-control" v-model="attributes.cellphone">
            </div>

          </div>
          <div class="card card-body mb-4">

            <div class="form-group">
              <label class="col-sm-12 col-form-label">Title</label>
              <input type="text" class="form-control" v-model="attributes.title">
            </div>

            <div class="form-group">
              <label class="col-sm-12 col-form-label">Role</label>
              <select class="form-control" v-model="attributes.role">
                <!-- <option value=""></option> -->
                <option value="Trader">Trader</option>
                <option value="Scheduler">Scheduler</option>
                <option value="Marketer">Marketer</option>
                <option value="Analyst">Analyst</option>
                <option value="Other">Other</option>
              </select>
            </div>

            <div class="form-group">
              <label class="col-sm-12 col-form-label">ICE Chat</label>
              <input type="text" class="form-control" v-model="attributes.aim">
            </div>
          </div>

          <div class="card card-body mb-4">

            <div class="form-group">
              <label class="col-sm-12 col-form-label">Street Address</label>
              <input type="text" class="form-control" v-model="attributes.street_1">
            </div>

            <div class="form-group">
              <label class="col-sm-12 col-form-label">Street Address 2</label>
              <input type="text" class="form-control" v-model="attributes.street_2">
            </div>

            <div class="form-group">
              <label class="col-sm-12 col-form-label">City</label>
              <input type="text" class="form-control" v-model="attributes.city">
            </div>

            <div class="form-group">
              <label class="col-sm-12 col-form-label">State</label>
              <input type="text" class="form-control" v-model="attributes.state">
            </div>

            <div class="form-group">
              <label class="col-sm-12 col-form-label">Zip / Postal Code </label>
              <input type="text" class="form-control" v-model="attributes.zip">
            </div>

            <div class="form-group">
              <label class="col-sm-12 col-form-label">Country</label>
              <input type="text" class="form-control" v-model="attributes.country">
            </div>

          </div>


          <div class="text-center">
            <button @click="save" class="btn btn-success ml-auto mr-4"><i class="fa fa-check"></i> Save</button>
            <!-- <router-link to="." class="btn btn-secondary mr-auto"><i class="fa fa-times"></i> Cancel</router-link> -->
          </div>
        </div>
      </div>
      <not-found v-else-if="error==404"></not-found>
      <div class="loader" v-else>
        <pulse-loader :color="'#74b3e0'" :loading="loading" :size="30" :sizeUnit="'px'"></pulse-loader>
      </div>
    </div>
</template>

<script>
  import dropMenu from '../components/drop-menu.vue'
  import { PulseLoader } from '@saeris/vue-spinners'
  import notFound from './notFound.vue'
  export default {
    props: ['title','endpoints'],
    data() {
      return {
        loaded: false,
        error: null,
        attributes: {
          id: '',
          username: '',
          company: '',
          first_name: '',
          last_name: '',
          phone: '',
          cellphone: '',
          aim: '',
          email: '',
          timezone: ''
        },
        fields: {
          id: {},
          username: {},
          company: {},
          first_name: {},
          last_name: {},
          phone: {},
          cellphone: {},
          aim: {},
          email: {},
          timezone: {}
        }
      }
    },
    created() {
      // console.log("welcome to add User view");
      // console.log(window.Auth.user().id);
      console.log(this.endpoints);
      this.fetchVM();
      this.fetchItem();
    },
    methods: {
      fetchVM() {
        fetch(this.endpoints.users,{
            method:'OPTIONS',
            headers: {
              'X-TOKEN': Auth.token(),
              'Content-Type': 'application/json',
            }
          })
          .then(res => this.checkResponse(res))
          .then(res => res.json())
          .then(res => {
            console.log(res);
            this.fields = res.fields;
          })
          .catch(err => console.log(err));
      },
      fetchItem() {
        fetch(this.endpoints.user, {
            headers: {
              'Content-Type': 'application/json',
              'X-TOKEN': Auth.token()
            }
          })
          .then(res => this.checkResponse(res))
          .then(res => res.json())
          .then(res => {
            this.attributes = res;
            this.loaded = true;
          })
          .catch(err => console.log(err));
      },
      save() {
        this.attributes.force_update = 0;
        fetch(this.endpoints.user, {
          method: 'post',
          body: JSON.stringify(this.attributes),
          headers: {
            'content-type': 'application/json',
            'X-TOKEN': Auth.token()
          }
        })
        .then(res => this.checkResponse(res))
        .then(res => res.json())
        .then( data => {
          alert('User Information Updated');
          // this.$router.push('/');
          // window.location('/');
          if(typeof(this.$route.query.to) !== 'undefined' && this.$route.query.to.length > 1) this.$router.replace({name: this.$route.query.to});
          else this.$router.push('/');
          this.$router.go(0);
        })
        .catch(err => console.log(err))
      },
      role_selected() {

      }
    },
    components: {
      dropMenu,
      PulseLoader,
      notFound
    }
  }
</script>
<style scoped>
.loader {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
</style>
