<template>

    <!-- Modal -->
    <div class="modal fade show" style="display: inline-block" id="messagesModal" tabindex="-1" role="dialog" aria-labelledby="messagesModal" :aria-hidden="show">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">

          <div class="modal-body" v-html="message.message">

          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-success mx-auto" data-dismiss="modal" @click="dismiss">OK</button>
          </div>
        </div>
      </div>
    </div>

</template>

<script>

export default {
  props: {
    messages : {
      default: function() {
        return [];
      }
    }
  },
  data() {
    return {

    }
  },
  computed: {
    show() {
      if(this.messages.length > 0) return true;
      return false;
    },
    message() {
      return this.messages[0];
    }
  },
  methods: {
    dismiss() {
      this.$emit('dismiss');
    }
  },
  components: {

  },
  mounted() {

  }
    // empty
}
</script>
